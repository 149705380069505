<template>
    <div class="base-box">

        <el-form ref="form" :model="agentActivityInfoVoList" label-width="140px">
            <el-row v-if="isUpdate">
                <el-col :span="12">
                    <el-form-item :label="`${type=='org'?'机构':'代理商'}编号`">
                        {{agentNo}}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="`${type=='org'?'机构':'代理商'}名称`">
                        {{agentName}}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-col :span="12">
                <el-form-item label="返现代付开关">
                    <el-select v-model="payType" disabled placeholder="请选择开关">
                        <el-option label="开启" value="1"></el-option>
                        <el-option label="关闭" value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <div style="text-align:right;" v-if="isUpdate&&!(agentLevel!='1'&&type=='agent')">
                <el-button  @click="addActive">新增活动</el-button>
            </div>
            <el-form-item label-width="0" required>
                <el-table
                    ref="multipleTable"
                    :data="agentActivityInfoVoList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    stripe
                    @selection-change="handleSelectionChange">
                    <el-table-column
                    v-if="!isUpdate"
                    type="selection"
                    label="全选"
                    width="55">
                    </el-table-column>
                    <el-table-column
                    label="活动名称"
                     prop="activityName">
                    </el-table-column>
                    <el-table-column
                    prop="policyName"
                    label="费率政策"
                    >
                    </el-table-column>
                    <!-- <el-table-column prop="status" label="状态" v-if="isUpdate">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.status" placeholder="请选择状态" :disabled="agentLevel!='1'" style="width: 80px">
                                <el-option label="启用" value="ENABLE"></el-option>
                                <el-option label="禁用" value="DISABLE"></el-option>
                            </el-select>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="激活返现"  v-if="isUpdate" width="100">
                        {{index}}
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.activeRecash" :minVal="0" :name="'activeRecash'+scope.$index" @getStatus="getStatus" :maxVal="scope.row.maxActiveRecash"></CashBackInput>
                            <!-- <template v-if="!(agentLevel!='1'&&type=='agent')"><CashBackInput v-model="scope.row.activeRecash" :minVal="0" :name="'activeRecash'+scope.$index" @getStatus="getStatus" :maxVal="scope.row.maxActiveRecash"></CashBackInput></template>
                            <template v-else>{{scope.row.activeRecash}}</template> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="重复激活返现"  v-if="isUpdate" width="110">
                        <template slot-scope="scope">
                            <el-form-item prop="repeatActiveRecash">
									<CashBackInput v-model="scope.row.repeatActiveRecash" :name="'repeatActiveRecash'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxRepeatActiveRecash"></CashBackInput>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="达标返现1"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.standardRecash1" :name="'standardRecash1'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxStandardRecash1"></CashBackInput>
                        </template>
                    </el-table-column>
                    <el-table-column label="达标返现2"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.standardRecash2" :name="'standardRecash2'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxStandardRecash2"></CashBackInput>
                        </template>
                    </el-table-column>
                    <el-table-column label="达标返现3"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.standardRecash3" :name="'standardRecash3'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxStandardRecash3"></CashBackInput>
                        </template>
                    </el-table-column>
                    <el-table-column label="4G返现1"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.flowRecash" :name="'flowRecash'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxFlowRecash"></CashBackInput>
                        </template>
                    </el-table-column>
                    <el-table-column label="4G返现2"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.flowRecash2" :name="'flowRecash2'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxFlowRecash2"></CashBackInput>
                        </template>
                    </el-table-column>
                    <el-table-column label="4G返现3"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.flowRecash3" :name="'flowRecash3'+scope.$index" @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxFlowRecash3"></CashBackInput>
                        </template>
                    </el-table-column>
                    <el-table-column label="税点"  v-if="isUpdate" width="100">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.taxPoint" :name="'taxPoint'+scope.$index" @getStatus="getStatus" :minVal="scope.row.minTaxPoint" :maxVal="scope.row.maxTaxPoint"></CashBackInput>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>
        <el-row :gutter="20" v-if="!isUpdate">
            <el-col :span="12" :offset="6">
                <el-button  type="primary" @click="prevClick">上一步</el-button>
                <el-button type="primary"  @click="nextClick()">提交</el-button>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isUpdate">
            <el-col :span="12" :offset="6">
                <el-button  @click="closeClick">关闭</el-button>
                <el-button type="primary" :loading="loading" :disabled="!(disabled=='')" @click="submitForm">提交</el-button>
            </el-col>
        </el-row>
        <el-dialog
        :visible.sync="acitveVisible" append-to-body
        width="1100px">
            <template>
                <el-table border :data="notHasPolicyInfoList" style="width: 100%" @selection-change="notSelectionChange">
                    <el-table-column
                    type="selection"
                    label="全选"
                    width="55">
                    </el-table-column>
                    <el-table-column prop="activityName" label="活动名称" />
                    <el-table-column prop="activityRemark" label="费率政策" />
                </el-table>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button  @click="acitveVisible = false">取 消</el-button>
                <el-button type="primary"  @click="addNewPolicy">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
  import CashBackInput from '@/components/cashBackInput'
  import { AgentApi } from '@/api'
  export default {
    data() {
      return {
        agentActivityInfoVoList: [],
        payType: '1',
        multipleSelection:[],
        newSelection:[],
        notHasPolicyInfoList: [],
        oldList: [],
        acitveVisible: false,
        loading: false,
        disabled:"",
        cashBackTaxPoint:'8',
        taxPointList: []
      }
    },
    props:{
        parentAgentNo:{
            type:[Number,String],
            default:0
        },
        type:{
            type:String,
            default: 'org'
        },
        isUpdate:{
            type: Boolean,
            default: false
        },
        agentNo:{
            type:[Number,String],
            default: 0
        },
        agentName:{
            type:String,
            default: ''
        },
        agentLevel:{
            type:String,
            default: ''
        },
        show:{
            type: Boolean,
            default:false
        }
    },
    watch:{
       show: {
           handler(val){
               if(val){
                this.agentActivityInfoVoList = []
                this.multipleSelection = []
                this.isUpdate ? this.getUpdateList() : this.getList()
            }
           },
           immediate:true
       },
       parentAgentNo: {
           handler(val){
            this.agentActivityInfoVoList = []
            this.multipleSelection = []
            this.isUpdate ? this.getUpdateList() : this.getList()
           },
           immediate:true
       }
    },
    created(){
        // this.isUpdate ? '' : this.getList()
    },
    components:{
        CashBackInput
    },
    methods:{
        addActive(){
            this.acitveVisible = true
        },
        addNewPolicy(){
           if(!this.newSelection){
               this.agentActivityInfoVoList = this.oldList
           } else{
               let list = JSON.parse(JSON.stringify(this.oldList))
               list = list.concat(this.newSelection)
               this.agentActivityInfoVoList = list
           }
           this.acitveVisible = false
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        notSelectionChange(val) {
            this.newSelection = val;
        },
        getList(){
            if(this.type == 'agent'){
                AgentApi.beforeSaveOneAgent(this.parentAgentNo)
                    .then(res=>{
                        if(res.success){
                            this.agentActivityInfoVoList = res.data.agentActivityInfoVoList
                            this.$forceUpdate()
                        } else{
                            this.agentActivityInfoVoList = ''
                        }
                    })
            } else{
                AgentApi.beforeSaveOrg(this.parentAgentNo)
                    .then(res=>{
                        if(res.success){
                            this.agentActivityInfoVoList = res.data.agentActivityInfoVoList
                            this.$forceUpdate()
                        } else{
                            this.agentActivityInfoVoList = ''
                        }
                    })
            }
        },
        getUpdateList(){
            AgentApi.beforeUpdateAgentActivity(this.agentNo)
                .then(res=>{
                    if(res.success){
                        this.agentActivityInfoVoList = res.data.hasPolicyInfoList
                        this.payType = res.data.agentInfoDo ? res.data.agentInfoDo.cashbackSwitch : '1'
                        this.oldList = res.data.hasPolicyInfoList ? JSON.parse(JSON.stringify(res.data.hasPolicyInfoList)) : []
                        this.cashBackTaxPoint = res.data.agentInfoDo.cashBackTaxPoint * 100
                        this.notHasPolicyInfoList = res.data.notHasPolicyInfoList
                        if(res.data.minTaxPoint&&res.data.maxTaxPoint){
                            this.taxPointList = []
                            for(let i = parseInt(res.data.minTaxPoint);i<parseInt(res.data.maxTaxPoint)+1;i++){
                                this.taxPointList.push({
                                    text:i+'%',
                                    value:i
                                })
                            }
                        }
                    }
                })
        },
        changeCollapse(val){
            console.log(val)
        },
        changePolicy(a,b){
            console.log(a)
            console.log(b)
        },
        closeClick(){
            this.$emit("change",true)
        },
        async updateClick(){
            this.loading = true
            let param = {agentActivityInfoDoList:''}
            param.agentActivityInfoDoList = this.agentActivityInfoVoList
            param.cashBackTaxPoint = this.cashBackTaxPoint
            let result = await AgentApi.updateAgentCashBack(this.agentNo,param)
            if(result.success){
                this.Message.success("修改成功")
                this.$emit("change",true)
            }
            this.loading = false
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
            if (valid) {
                this.updateClick()
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        nextClick(){
            if(this.multipleSelection.length<=0){
                this.Message("请选择返现配置")
                return
            }
            console.log(this.multipleSelection)
            this.$emit('change',this.multipleSelection,'submit')
        },
        prevClick(){
            this.$emit('change','','prev')
        },
        getStatus(name,status){
            if(status=='success'){
                this.disabled = this.disabled.replace(name,"")
            } else{
                if(this.disabled.indexOf(name)>-1){
                    return
                }
                 this.disabled+=name
            }
        }
    }
  }
</script>
