<template>
	<div class="cost-input">
		<input
			class="el-input__inner"
			:style="isRed ? 'border-color:red' : ''"
			v-model="value"
			@blur="checkVal"
			@input="inputVal"
			@focus="onfocus"
			:placeholder="`${minVal}-${maxVal}`"
		/>
	</div>
</template>
<script>
export default {
	data() {
		return {
			defaultVal: "",
			isRed: false
		};
	},
	props: ["value", "minVal", "maxVal", "name"],
	created() {
		this.defaultVal = this.value;
	},
	methods: {
		checkVal(e) {
			var regu =
				"(^[1-9]([0-9]+)?(\.[0-9]*)?$)|(^(0){1}$)|(^(0){1}\.[0-9]+$)";
			var re = new RegExp(regu);
			if (this.value) {
				if (!re.test(this.value)) {
					this.value = "";
					this.$emit("input", "");
					this.$emit("getStatus", this.name, "fail");
					this.isRed = true;
					this.$message.error(`请输入正确的数字`);
				} else if (this.value > this.maxVal) {
					this.value = "";
					this.$emit("input", "");
					this.isRed = true;
					this.$emit("getStatus", this.name, "fail");
					this.$message.error(`不能大于${this.maxVal}`);
				} else if (this.value < this.minVal) {
					this.value = "";
					this.$emit("input", "");
					this.isRed = true;
					this.$emit("getStatus", this.name, "fail");
					this.$message.error(`不能小于${this.minVal}`);
				} else {
					this.isRed = false;
					this.$emit("getStatus", this.name, "success");
				}
			} else {
				this.isRed = true;
				this.$emit("getStatus", this.name, "fail");
			}
		},
		onfocus() {
			this.$emit("getStatus", this.name, "fail");
		},
		inputVal(e) {
			this.$emit("input", e.target.value);
		}
	}
};
</script>
<style lang="scss">
.cost-input {
	display: inline-block;
	input {
		width: 80px;
	}
}
</style>
